html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}
html {
    scroll-behavior: smooth;
  }
* {
    font-family: Poppins, sans-serif;
}

dl, ol, ul {
    margin-top: 0px;
    margin-bottom: 0rem !important;
    padding-left: 0rem !important;
}
.onlymobadd  {
  display: none;
  }
li {
    list-style: none;
}

a {
    text-decoration: none;
}

main {
    overflow-x: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
@font-face {
    font-family: 'Chandler-Mountain';
    src: url(../fonts/CHANDLER-MOUNTAIN-CHANDLER-MOUNTAIN-MEDIUM-500.OTF);
}


  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');




  .concert-content.up {
    top: -15px;
}




/* header */


.header-ab input#menu {
    /* display: none; */
}

.icon {
    /* background: #FFF; */
    /* cursor: pointer; */
    /* display: block; */
    /* height: 24px; */
    /* padding: 16px; */
    /* width: 24px; */
}

label.icon {
    /* position: absolute; */
    /* z-index: 200; */
    /* top: 320px; */
}

.icon .menu,
.icon .menu::before,
.icon .menu::after {
    background: #9FB1BD;
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    transition: background ease .3s, top ease .3s .3s, transform ease .3s;
    width: 20px;
}

.header-ab .icon:hover .menu,
.header-ab .icon:hover .menu::before,
.header-ab .icon:hover .menu::after {
    background: #47B74B;
}

.header-ab .icon .menu {
    left: 13px;
    top: 22px;
}

.header-ab .icon .menu::before {
    top: -6px;
}

.header-ab .icon .menu::after {
    top: 6px;
}

.header-ab #menu:checked + .icon .menu {
    background: transparent;
}

.header-ab #menu:checked + .icon .menu::before {
    transform: rotate(45deg);
}

.header-ab #menu:checked + .icon .menu::after {
    transform: rotate(-45deg);
}

.header-ab #menu:checked + .icon .menu::before,
.header-ab #menu:checked + .icon .menu::after {
    top: 0;
    transition: top ease .3s, transform ease .3s .3s;
}

.header-ab nav {
    background: #F8F8F8;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, .1); */
    height: 100vh;
    /* padding-top: 60px; */
    position: fixed;
    transition: width ease .6s;
    width: 300px;
    z-index: 999999999999999999;
    overflow: hidden;
    position: fixed;
    transition: all 0.5s ease-in-out;
}
.logo-head img {
    margin: auto;
    width: auto;
}
.header-ab #menu:checked ~ nav {
    width: 100%;
    transition: all 0.5s ease-in-out;
}

.header-ab nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.header-ab nav li {
    cursor: pointer;
    display: block;
    line-height: 60px;
    padding-left: 16px;
    white-space: nowrap;
}
.header-ab nav i {
    color: #9FB1BD;
    margin-right: 24px;
    vertical-align: text-bottom;
}
.menus-head {
    /* width: 50px; */
    /* height: 50px; */
    /* background: red; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* border-radius: 50%; */
    /* position: relative; */
    /* left: 100px; */
    /* border: 2px solid #3B3B3B; */
    /* top: 0px; */
}
.tabs-header {
    position: absolute;
    left: 460px;
    top: 7rem;
    transition: all 0.5s ease-in-out;
}

header.header-ab {
    height: 100%;
    width: 100%;
}

.swiper-slide h2 {
    font-size: 78px;
    position: absolute;
    font-weight: 700;
    color: #3B3B3B;
    position: absolute;
    bottom: -15px;
    left: 50px;
    font-family: 'Chandler-Mountain';
}
.logo-head {
    text-align: center;
    position: absolute;
    left: 26px;
    top: 35px;
}
.menus-head p {
    font-size: 22px;
    font-family: 'Chandler-Mountain';
    font-weight: 500;
    position: relative;
    bottom: -60px;
}
.header-ab #menu:checked ~ nav .header-contat-info {
    display: flex;
}
.header-ab nav .header-contat-info {
    /* display: none; */
}
.header-contat-info a {
    display: flex;
    gap: 17px;
    text-decoration: none;
    font-size: 25px;
    font-family: 'Poppins';
    color: black !important;
    font-weight: 300;
    position: relative;
    width: 210px;
}

.header-contat-info a:before {
    position: absolute;
    left: -120px;
    top: 50%;
    background: red;
    width: 90px;
    height: 1px;
    content: '';
}
.slider-container {
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    /* width: 100%; */
    /* height: 100vh; */
    scrollbar-width: none; /* Hide the scrollbar */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer and Edge */
  }
  
  .slider {
    display: flex;
    height: auto;
    /* gap: 30px; */
  }
  
  .slide {
    flex: 0 0 33%; /* Each slide takes up 100% of the container width */
    position: relative;
    /* overflow: hidden; */
    /* padding-bottom: 40px; */
    transition: all 0.5s ease-in-out;
  }
  
  .slide img {
    width: 100%;
    height: auto;
    /* z-index: 99999999999; */
    /* position: relative; */
    height: 700px;
    background-color: blue;
    margin-right: -2.5px;
    margin-left: -2.5px;
    padding-right: 0px;
    padding-left: 0px;
    cursor: pointer;
    object-fit: cover !important;
  }
  












  .bg-video-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 90vh;
    background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat center center/cover;
  }
  video {
    min-width: 100%;
    min-height: 100vh;
    z-index: 1;
    height: 20px;
    object-fit: cover;
  }
  .overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    /* background-image: linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%); */
    background-size: 3px 3px;
    z-index: 2;
    background: #00000091;
    z-index: 1;
  }

  .social-icons {
    display: flex;
    flex-direction: column;
    gap: 18px;
    position: absolute;
    bottom: 6rem;
    left: 124px;
    justify-content: center;
    align-items: center;
}

.social-icons img {
    width: fit-content;
}
.header-contat-info {
    position: absolute;
    top: -5rem;
    left: 14%;
    display: flex;
    gap: 24rem;
    /* transform: translateX(-50%); */
    transition: all 0.5s ease-in-out;
}
.headMeanus ul li a {
    color: black;
    font-size: 25px;
    font-family: 'Chandler-Mountain';
    text-decoration: none;
    position: relative;
    z-index: 99999999999;
    padding: 6px 13px;
    transition: all 0.5s ease-in-out;
}
.headMeanus {
  position: absolute;
  left: -170px;
  top: 30%;
  transform: translateY(-50%);
}
#content {
    /* overflow: hidden; */
    /* max-height: 56px; */
    /* transition: max-height 0.5s ease; */
    width: 91%;
    margin-bottom: 0;
  }
  
    #content.expanded {
      max-height: none;
    }
  
    .banner-content {
      position: absolute;
      left: 40px;
      top: 20%;
      z-index: 1;
      /* transform: translateY(-50%); */
      margin-left: 40px;
  }

  .side-space {
      margin-left: 300px;
  }
  .banner-content h1 {
    color: white;
    font-size: 75px;
    font-family: 'Chandler-Mountain';
    width: 120%;
  }
  
  .banner-content p {
    color: white;
    font-size: 18px;
    font-family: 'Poppins';
    margin: 0;
  }
  button.see-more-button {
    width: 90px;
    background: none;
    color: red;
    border: none;
    padding: 0;
  }


  .banner-content:before {
    content: '';
    position: absolute;
    left: -29px;
    top: 14px;
    background: red;
    width: 20px;
    height: 96%;
}
body.over-remove {
    overflow: initial !important;
    overflow-x: hidden !important;
}
body.over-remove.nav {
    overflow: hidden !important;
}
div#menu {
    position: absolute;
    left: 100px;
    top: 33%;
    transform: translateY(-50%);
}
.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 69px;
  height: 64px;
  cursor: pointer;
  margin: 0px auto;
  padding: 18px 14px;
  border: 2px solid black;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
  }
  
  .burger span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s ease 0s;
  }
  
  .burger.expanded span:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }
  
  .burger.expanded span:nth-child(2) {
    opacity: 0;
  }
  
  .burger.expanded span:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
  .onlymobileshow{
    display: none;
  }
  nav.expanded {
    width: 100%;z-index: 9999999999999999999999999999999999;
  }
  
  nav.collapsed .header-contat-info {
    display: none;
  }
  
  .banner-content a {
    color: red;
    text-decoration: none;
    font-size: 18px;
    font-family: Poppins;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
  div#menu p {
    font-size: 22px;
    font-family: 'Chandler-Mountain';
    text-align: center;
  }
  .side-padding{
    padding-left: 80px;
    width: 93%;
  }
  
  h1.home-title {
    font-size: 75px;
    font-weight: 500;
    font-family: 'Chandler-Mountain';
  }
  
  .concert-content p {
    font-size: 25px;
    font-family: 'Poppins';
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .solid-btn a {
    font-size: 18px;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
    color: white;
    text-decoration: none;
    font-weight: 500;
    font-family: 'Poppins';
    margin-top: 20px;
    transition: 0.5s ease-in-out;
    border: 2px solid red;
  }
  .concert-content:before {
    content: '';
    position: absolute;
    left: -29px;
    top: 14px;
    background: red;
    width: 20px;
    height: 9%;
    transition: all 0.5s ease-in-out;
  }
  .concert-content:hover:before {
    content: '';
    position: absolute;
    left: -29px;
    top: 14px;
    background: red;
    width: 20px;
    height: 98%;
    transition: all 0.5s ease-in-out;
  }
  .concert-content {
    position: relative;
    /* top: 50px; */
    width: 100%;
    left: 10px;
  }
  .concert-img img {
    width: 100%;
    z-index: 1;
    position: relative;
  }
  section.sec-4 .border-div {
    right: 0;
}

  .concert-img img:nth-child(2) {
    position: relative;
    left: -260px;
    top: -140px;
    z-index: 0;
    width: 80%;
  }
  section.sec-2 {
    padding-top: 100px;
  }

  .border-div {
    width: 240px !important;
    background: red;
    height: 2px;
    position: relative;
    top: -260px;
    right: 28rem;
    margin-left: auto;
    z-index: -1;
    transition: transform 0.2s ease-out;
}
  .concert-img {
    position: relative;
  }
  .col-lg-5.epoxy-col img {
    transform: translate(-200px, -70px);
    position: relative;
    top: 80px;
    width: 703px;
    /* width: 129%; */
  }
  section.sec-3 {
    padding-bottom: 100px;
  }
  section.sec-3 .border-div {
    left: 0;
    right: initial;
    margin: auto;
    bottom: 80px;
    top: initial;
    margin: initial;
  }
  footer {
    padding-left: 380px;
    background: #F8F8F8;
    padding-top: 80px;
    position: relative;
    z-index: 1;
    padding-bottom: 320px;
  }
  .footer-subcribe h4 {
    font-size: 40px;
    font-family: 'Chandler-Mountain';
    font-weight: 500;
  }
  
  .footer-subcribe form input {width: 350px;height: 55px;border: 1px solid #707070;padding: 0 20px;}
  
  .footer-subcribe form a {
    width: 160px;
    height: 55px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    left: -10px;
    position: relative;
  }
  
  .footer-subcribe form {
    display: flex;
    margin-top: 15px;
    margin-bottom: 40px;
  }
  
  .footer-subcribe form input::placeholder {
    color: #707070;
  }
  
  .footer-subcribe ul li a {
    text-decoration: none;
    font-size: 25px;
    font-family: 'Poppins';
    color: #3B3B3B;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    position: relative;
    z-index: 99999999999;
    padding: 6px 13px;
    transition: all 0.5s ease-in-out;
  }
  footer .header-contat-info {
    left: 510px;
    right: initial;
    bottom: 150px;
    top: initial;
    padding-top: 70px;
  }
  .copyright {
    background: red;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 160px;
    align-items: center;
    z-index: 999;
    position: relative;
  }
  
  .copyright ul {
    display: flex;
    color: white;
    font-size: 12px;
    font-family: poppins;
    /* gap: 30px; */
  }
  
  .copyright ul li a {
    color: white;
    font-size: 12px;
    font-family: poppins;
    text-decoration: none;
    padding: 0 31px;
    border-left: 1px solid white;
    transition: all 0.5s ease-in-out;
  }
  .copyright p {
    color: white;
    font-size: 12px;
    font-family: poppins;
    text-decoration: none;
    margin: 0;
  }
  .copyright ul li:first-child a{
    border:none;
  }
  
  
  
  
  
  
  
  
  
  /* concret page */
  section.sec-1.concrete-sec-1 .bg-video-wrap {
    height: 50vh;
  }
  
  
  section.sec-1.concrete-sec-1 .banner-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    margin-left: 0px;
  }
  
  section.sec-1.concrete-sec-1 .banner-content:before {
  
    height: 60%;
  }
  .inner-title h1 {
    font-size: 55px;
    font-weight: 500;
    font-family: 'Chandler-Mountain';
    position: relative;
    text-align: center;
    display: initial;
    color: #3B3B3B;
  }
  
  .inner-title p {
    font-size: 18px;
    font-family: 'Poppins';
    width: 87%;
    margin: 0 auto;
  }
  
  .inner-title span {
    content: '';
    position: absolute;
    left: -30px;
    top: 4px;
    background: red;
    width: 20px;
    height: 84%;
}
  
  .inner-title {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  
  .inner-title:before {}
  
.Grand-content img {
  height: 125px;
}
  .inner-title h1:before {
    width: 170px !important;
    background: red;
    height: 2px;
    position: absolute;
    top: 30px;
    right: -200px;
    content:'';
  }
  .inner-title h1:after {
    width: 170px !important;
    background: red;
    height: 2px;
    position: absolute;
    top: 30px;
    left: -230px;
    content:'';
  }
  section.sec-2-concret {
    padding-top: 100px;
    /* padding-bottom: 70px; */
  }
  .grind {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;
  }
  section.sec-2-concret .Grand-content {
    width: 85%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 80px !important;
  }
  section.sec-2-concret .Grand-content img {
    margin-bottom: 15px;
  }
  section.sec-2-concret .row {
    justify-content: center;
  }
  .header-ab nav .header-contat-info a img {
    width: 30px;
    height: 30px;
}

  
  .Grand-content h2 {
    font-size: 32px;
    font-family: 'Poppins';
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  
  .Grand-content p {
    font-size: 22px;
    font-weight: 400;
    font-family: 'Poppins';
  }
  .circle:hover {
    transform: translate(-50%,50%) scale(1.2);
}
  /* Scrollbar Styling */
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d; 
  }
  .single-para {
    width: 80%;
    text-align: center;
    position: relative;
    margin: 0 auto;
    padding: 0px 0px;
    /* margin: 30px 0 50px 0px; */
    margin-top: 30px !important;
    margin-bottom: 50px !important;
}

.single-para:before {
    width: 130px !important;
    background: red;
    height: 2px;
    position: absolute;
    top: -10px;
    right: 0;
    content: '';
}

.single-para:after {
    width: 130px !important;
    background: red;
    height: 2px;
    position: absolute;
    left: 0;
    content: '';
    bottom: -10px;
}

p {}

.single-para p {
    width: 80%;
    font-size: 20px;
    font-family: 'Poppins';
    font-weight: 500;
    margin: 0 auto;
}
.step-concret {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.step-concret img {
  width: fit-content;
}

.step-concret {
  text-align: center;
}

.step-concret strong {
  font-size: 50px;
  color: #E80000;
}

.step-concre h2 {
  font-size: 32px;
  font-family: 'Poppins';
  font-weight: 600;
}

.step-concre p {
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 300;
}
span.path-steps {
  position: absolute;
  left: 71%;
  transform: translateX(-50%);
  z-index: -1;
  top: 15%;
}
section.sec-3-concret .single-para {
  width: 100%;
}
section.sec-3-concret .single-para p{
  width: 100%;
}



section.sec-2-concret.s-4 .single-para {
  width: 100%;
}
section.sec-2-concret.s-4 .single-para p{
  width: 100%;
}
.furter-inquiry {
  text-align: center;
  padding: 50px 0 50px 0px;
}

.furter-inquiry p {
  font-size: 22px;
  font-family: 'Poppins';
  font-weight: 500;
  color: #3B3B3B;
}

.furter-inquiry h5 {
  font-size: 24px;
  font-family: 'Poppins';
  font-weight: 600;
  color: #3B3B3B;
}

.furter-inquiry p a {
  text-decoration: none;
  color: #3B3B3B;
}
p.oneline-para {
  font-size: 22px;
  font-weight: 500;
  font-family: 'Poppins';
  text-align: center;
  padding-bottom: 50px;
}
section.sec-2-concret.s-4.timber-sec4 .inner-title h1:before {
  right: -350px;
}
section.sec-3.about-sec1 .concert-content p {
  font-size: 25px;
  font-weight: 500;
  width: 88%;
}
.side-space.about-page section.sec-4 {
  padding-bottom: 70px;
}

.side-space.about-page section.sec-3 {
  padding-bottom: 70px;
}
.contact-page {
  background: #F8F8F8;
}
.contact-page h1.home-title {
  position: relative;
}
.contact-page h1.home-title:before {
  width: 130px !important;
  background: red;
  height: 2px;
  position: absolute;
  bottom: 50px;
  left: 260px;
  margin-left: auto;
  content: '';
}
form.contact-form input {
  width: 100%;
  height: 57px;
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 13px;
  background: #EEEEEE;
  border: none;
}

form.contact-form textarea {
  width: 100%;
  height: 137px;
  padding: 20px 20px;
  border-radius: 13px;
  background: #EEEEEE;
  border: none;
}

form.contact-form textarea::placeholder {
  color: #BCBCBC;
  font-family: 'Poppins';
  font-size: 16px;
}

form.contact-form input::placeholder {
  color: #BCBCBC;
  font-family: 'Poppins';
  font-size: 16px;
}
form.contact-form input:focus-visible {
  color: #6b0a0a;
  outline-color: red;
}
form.contact-form textarea:focus-visible {
  color: #6b0a0a;
  outline-color: red;
}

section.sec-1-contact {
    padding: 100px 0;
    width: 90%;
}
section.sec-1-contact a {
  width: 160px;
  height: 55px;
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 500;
  margin-left: auto;
  margin-top: 20px;
  transition: all 0.5s ease-in-out;
  border: 2px solid red !important;
}
.thanks .modal-dialog.modal-dialog-centered {
  max-width: 670px;
}
.thanks .concert-content {
  width: 61%;
  margin: 0 auto;
}
.thanks .modal-header {
  border: none;
}

.thanks .modal-body {
  padding: 30px 0;
}
.thanks .concert-content:before {
  height: 48%;
}

















.slide:hover img {
  width: 110%;
  transition: all 0.5s ease-in-out;
}



.slide:hover {
  z-index: 99999999999;
  transition: all 0.5s ease-in-out;
  flex: 0 0 35%;

}








.swiper-container {
  width: 100%;
  /* height: 700px; */
  overflow: hidden;
  transition: all 0.5s ease-in-out;

}

.swiper-slide {
  /* width: 20% !important; */
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* font-size: 24px; */
  /* transition: all 0.5s ease-in-out; */
  width: 29.4rem !important;
  height: 100%;
}

.swiper-slide:hover {
  /* width: 23% !important; */
  /* transition: all 0.5s ease-in-out; */
}

.swiper-slide:hover img {
  width: -webkit-fill-available;
  transition: all 0.5s ease-in-out;
}


.swiper-slide img {
  height: 670px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}










center{
  position: absolute;
  top: 50%;   left: 50%;
  transform: translate(-50%,-50%);
}
.center .box{
  position: relative;
  width: 0px;
  height: 0px;
  /* background: #fff; */
  transition: .5s;
  border-radius: 50px;
  transition-delay: .9s;
}
.center .box.active{
  width: 200px;
  height: 250px;
  border-radius: 0px;
  transition-delay: 0s;
  /* margin: 0 auto; */
  position: relative;
  top: -340px;
}
.center .box ul{
  position: relative;
  width: 100%;  height: 100%;
  transform: scale(0);
  transition: .5s;
  transition-delay: 0s;
}
.center .box.active ul{
  transform: scale(1);
  transition-delay: .9s;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.center .box ul li{
  list-style: none;
  width: 100%;
  border-bottom: 1px solid #262626;
}
.center .box ul li a{
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  transition: 1s;
  font-size: 14px;
  text-align: center;
  color: white;
  font-family: 'Chandler-Mountain';
}
.center .box ul li a span:nth-child(2){
  color: #262626;
}
.center .box ul li a span:nth-child(1){
  /* color: #fff; */
  /* width: 30px; */
  /* height: 30px; */
  /* text-align: center; */
  /* line-height: 30px; */
  /* display: inline-block; */
}

.center .box ul li:nth-child(1) a span:nth-child(1){
  /* background: #3b5999; */
}
.center .box ul li:nth-child(2) a span:nth-child(1){
  /* background: #55acee; */
}
.center .box ul li:nth-child(3) a span:nth-child(1){
  background: #dd4b39;
}
.center .box ul li:nth-child(4) a span:nth-child(1){
  background: #0077B5;
}
.center .box ul li:nth-child(5) a span:nth-child(1){
  background: #00AFF0;
}


/************ ul li a:hover*************/
.center .box ul li a:hover{
  transform: scale(1.2);
}
.center .box ul li a:hover span{
  color: #fff;
}
/*.center .box ul li:nth-child(1) a:hover{*/
/*  background: #3b5999;*/
/*}*/
/*.center .box ul li:nth-child(2) a:hover{*/
/*  background: #55acee;*/
/*}*/
.center .box ul li:nth-child(3) a:hover{
  background: #dd4b39;
}
.center .box ul li:nth-child(4) a:hover{
  background: #0077B5;
}
.center .box ul li:nth-child(5) a:hover{
  background: #00AFF0;
}

/************  .center .toggle  *************/
.toggle {
  position: fixed;
  bottom: 65px;
  right: 0px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}



.center .toggle{
  position: absolute;
  right: 10%;
  bottom: -80px;
  transform: translate(-50%,50%);
  width: 120px;
  height: 120px;
  background: #ff0000;
  text-align: center;
  line-height: 120px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  /* align-items: center; */
  /* justify-content: center; */
  /* display: flex; */
}
.center .toggle span{
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
  transition: .9s;
 
}
.social-icons img:hover {
    transform: translateX(-10px);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
  }
.center .toggle.active span{
  transform: translateY(-100%);
}
.center {
  position: fixed;
  top: 82%;
  right: 0;
  z-index: 999999999;
}
.burger:hover span {
    color: white;
    background: white;
}
.center .box ul li {
  background: red;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
section.sec-3.about-sec1 .concert-img img {
  width: auto;
  z-index: -1;
}

section.sec-3.about-sec1 .concert-img {
  left: -74%;
  z-index: -1;
}
section.sec-3.about-sec1 .border-div {
  right: 130px !important;
  left: initial !important;
  margin-left: auto;
  width: 40% !important;
  bottom: 250px !important;
}

.side-space.about-page section.sec-3.about-sec1 {
  padding-top: 80px;
}


.solid-btn a:hover {
  background: none;
  color: black;
}


.footer-subcribe ul li a:before {
  background: red;
  position: absolute;
  left: 0;
  bottom: 0;
  height:100%;
  width: 0%;
  content:'';
  z-index:0;
  transition: all 0.5s ease-in-out;
}


.footer-subcribe ul li {
  display: flex;
  z-index:999;
  transition: all 0.5s ease-in-out;
  /* position: relative; */
}

.footer-subcribe ul li a:hover:before {
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: -1;
  color: white;
}
.footer-subcribe ul li:hover a {
  color: white;
}
.banner-content a:hover {
  color: white;
}
.burger:hover {
  background: red;
  color: white;
  border-color: white;
}
.copyright ul li a:hover {
  color: black;
}
.headMeanus ul li a:before {
  background: red;
  position: absolute;
  left: -7px;
  bottom: 0;
  height: 100%;
  width: 0px;
  content: '';
  z-index: 0;
  transition: all 0.5s ease-in-out;
}

.headMeanus ul li a:hover:before {
  width: 11px;
  transition: all 0.5s ease-in-out;
  z-index: -1;
  color: white;
}









/* testimonial */

/* ----=----------testimonial-section----=---------- */

.testimonial .ad-test {
  /* display: flex; */
  align-items: center;
  width: 70%;
  margin: 0 auto;
  gap: 20px;
}
.test-content span {
  font-size: 80px;
  color: black;
}



.test-content {
  display: flex !important;
  display: flex;
  align-items: center;
  /* width: 70%; */
  margin: 0 auto;
  gap: 20px;
  background: #FDE0E0;
}
.test-img {
  border-left: 7px solid #ff0000;
}
.testimonial .slick-prev:before {

content: "\f060" !important;

z-index: 999999999999999999;

position: relative;

font-family: "Font Awesome 6 Free" !important;

font-weight: 800;

left: -120px;
}
.testimonial .slick-next:before, [dir=rtl] .slick-prev:before {
  font-family: "Font Awesome 6 Free" !important;
  content: "\f061" !important;
  font-weight: 800;
  right: -120px;
  position: relative;
}
.testimonial .slick-next:before, .slick-prev:before {
  font-size: 35px !important;
  line-height: 1;
  opacity: .75;
  color: #fff;
}
.parent-testimonial {
  padding-bottom: 120px;
}
.test-content h6 {
  font-size: 18px;
  font-family: 'Poppins';
  width: 70%;
  font-weight: 700;
}
.test-content p {
  font-size: 18px;
  font-family: 'Poppins';
  width: 70%;
}
span.client-left {
  position: absolute;
  left: 0;
  top: 0;
}
.margin-fominus {
  margin-top: -5rem;
}
span.client-right {
  position: absolute;
  right: 0;
  top: 0;
}

.testimonial {
  position: relative;
}
a.see-about {
  color: red !important;
  text-decoration: none;
  cursor: pointer;
}


/*  */
.modal.ab-modal .inner-title p {
  width: 100%;
  text-align: left;
}

.modal.ab-modal .modal-header {
  border: none;
}

.modal.ab-modal .modal-dialog.modal-dialog-centered {
  width: 100%;
  max-width: 80%;
}

.modal.ab-modal .inner-title {
  padding: 0 30px;

}
.modal.ab-modal .inner-title h1 {

  top: -11px;
}
section.sec-1-contact a:hover {
  background: none;
  color: black !important;
}

.border-div2 {
  width: 240px !important;
  background: red;
  height: 2px;
  position: relative;
  top: -260px;
  right: 58rem;
  margin-left: auto;
  z-index: -1;
  transition: transform 0.2s ease-out;
}

.border-div3 {
  width: 240px !important;
  background: red;
  height: 2px;
  position: relative;
  top: -190px;
  left: -38rem;
  z-index: -1;
  transition: transform 0.2s ease-out;
}

.ul-li-concrete ul {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 30px;
}

.ul-li-concrete ul li {
  width: 48%;
  text-align: left;
  display: block;
  font-size: 18px;
  font-family: 'Poppins';
  font-weight: 400;
  margin-bottom: 17px;
}

.ul-li-concrete {
  width: 41%;
  margin: 0 auto;
}
.ul-li-concrete ul li {
  position: relative;
}

.ul-li-concrete ul li:before {
  position: absolute;
  left: -20px;
  top: 9px;
  width: 10px;
  height: 10px;
  background: black;
  content: '';
  border-radius: 50%;
}









/*body.loaded {*/
/*  overflow-y: auto;*/
/*}*/

/*.overlay {*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  z-index: 100000000;*/
/*}*/
/*.overlay .overlayDoor:before, .overlay .overlayDoor:after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  width: 50%;*/
/*  height: 100%;*/
/*  background: #111;*/
/*  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);*/
/*  transition-delay: 0.8s;*/
/*}*/
/*.overlay .overlayDoor:before {*/
/*  left: 0;*/
/*}*/
/*.overlay .overlayDoor:after {*/
/*  right: 0;*/
/*}*/
/*.overlay.loaded .overlayDoor:before {*/
/*  left: -50%;*/
/*}*/
/*.overlay.loaded .overlayDoor:after {*/
/*  right: -50%;*/
/*}*/
/*.overlay.loaded .overlayContent {*/
/*  opacity: 0;*/
/*  margin-top: -15px;*/
/*}*/
/*.overlay .overlayContent {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  transition: 0.5s cubic-bezier(0.77, 0, 0.18, 1);*/
/*}*/
/*.overlay .overlayContent .skip {*/
/*  display: block;*/
/*  width: 130px;*/
/*  text-align: center;*/
/*  margin: 50px auto 0;*/
/*  cursor: pointer;*/
/*  color: #fff;*/
/*  font-family: "Nunito";*/
/*  font-weight: 700;*/
/*  padding: 12px 0;*/
/*  border: 2px solid #fff;*/
/*  border-radius: 3px;*/
/*  transition: 0.2s ease;*/
/*  z-index: 9999999999999999999999999999;*/
/*}*/
/*.overlay .overlayContent .skip:hover {*/
/*  background: #ddd;*/
/*  color: #444;*/
/*  border-color: #ddd;*/
/*}*/

/*.loader {*/
/*  width: 128px;*/
/*  height: 128px;*/
/*  border: 3px solid #fff;*/
/*  border-bottom: 3px solid transparent;*/
/*  border-radius: 50%;*/
/*  position: relative;*/
/*  -webkit-animation: spin 1s linear infinite;*/
/*          animation: spin 1s linear infinite;*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/
/*.loader .inner {*/
/*  width: 64px;*/
/*  height: 64px;*/
/*  border: 3px solid transparent;*/
/*  border-top: 3px solid #fff;*/
/*  border-radius: 50%;*/
/*  -webkit-animation: spinInner 1s linear infinite;*/
/*          animation: spinInner 1s linear infinite;*/
/*}*/

/*@-webkit-keyframes spin {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*@keyframes spin {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/
/*@-webkit-keyframes spinInner {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(-720deg);*/
/*  }*/
/*}*/
/*@keyframes spinInner {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  100% {*/
/*    transform: rotate(-720deg);*/
/*  }*/
/*}*/

/*body.over-remove .center {*/
/*  display: none;*/
/*}*/


/*body.over-remove.loaded .center {*/
/*    display: block;*/
/*}*/


.center .toggle span img {
    width: 56%;
}
.headMeanus ul li a:hover {
    color: red;
}
.loader-page {
      background-color: #333c;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 99999999999999999999;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .5s ease-out;
    }
    .loader-container {
      /* border: 1px solid steelblue; */
      width: 600px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .loader {
      width: 500px;
      height: 10px;
      background-color:white;
      position: relative;
    }
    .loader-fill {
      background-color: red;
      width: 0px;
      height: 100%;
      position: absolute;
    }
    .loader-counter {
      position: absolute;
      font-size: 5rem;
      font-family: 'Lato' ;
      font-weight: 300;
      letter-spacing: -5px;
          color: white;
      z-index: 9;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }


    section.sec-3.about-sec1.home-sec {
      padding-top: 100px;
  }
  
  section.sec-3.about-sec1.home-sec h1.home-title {
      font-size: 27px;
      font-weight: 700;
      font-family: 'Poppins';
  }
  
  section.sec-3.about-sec1.home-sec .concert-content:hover:before {
       top: 5px;
  }
  section.sec-3.about-sec1.home-sec .concert-content:before {
      top: 5px;
  }
  .col-lg-7.home-con .concert-content {
    position: absolute;
    bottom: 2rem;
    left: 19rem;
    top: initial;
    width: 100%;
}
.home-pg   .col-lg-7.home-con .concert-content{
  bottom: 40%;
}
.col-lg-7.home-con {
    position: relative;
}
section.sec-3.about-sec1.home-sec p {
    width: 100%;
    margin-bottom: 10px;
    text-transform: uppercase;
}
p.extra-para {
  text-align: center;
  font-size: 33px;
  color: #3B3B3B;
  font-family: 'Poppins';
  font-weight: 600;
}
.toggle.active {
  bottom: 174px;
}
.side-space.about-page section.sec-3.about-sec1 .border-div {
  right: 470px !important;
  left: initial !important;
  margin-left: auto;
  width: 20% !important;
  bottom: 250px !important;
  z-index: -2;
}
.side-space.about-page section.sec-3.about-sec1 .concert-content p {
  font-size: 18px;
  font-weight: 400;
  width: 88%;
}
.drp-iner {
  z-index: 99999;
  position: relative;
  top: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.drp-iner ul li a {
  /* position: relative; */
  /* display: inline-block; */
  /* margin-top: -3.33333rem; */
  /* margin-left: 1.06667rem; */
  /* transform: translateZ(0); */
  font-size: 30px;
  transition: transform .45s cubic-bezier(0.365, 0.305, 0, 1) 0.05s;
  font-family: 'Chandler-Mountain';
  font-weight: bold;
  color: #3B3B3B;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.drp-iner ul li {line-height: 22px;}

.swiper-slide.hover-eeff:hover .drp-iner {
  opacity: 1;
  transition: all 0.5s ease-in-out;
}


.drp-iner ul li a:before {
  background: red;
  position: absolute;
  left: -15px;
  bottom: 0;
  height: 100%;
  width: 0px;
  content: '';
  z-index: 0;
  
  transition: all 0.5s ease-in-out;
}
.drp-iner ul li a:hover:before{
  width: 11px;
}
.drp-iner ul li a:hover{
color: red;
}
section.sec-4 section.sec-3.about-sec1.home-sec {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

section.sec-4 section.sec-3.about-sec1.home-sec .inner-title h1:before{

  display: none;
}
section.sec-4 section.sec-3.about-sec1.home-sec .inner-title h1{
font-size: 75px;
}
section.sec-4 section.sec-3.about-sec1.home-sec .inner-title h1:after{

  display: none;
}
       
/* ______ parallax */

.parallax-container {
  position: relative;
  overflow: hidden;
  min-height: 500px;
}


.parallax-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(0%);
  transition: transform 0.2s ease;
}


.red-text {
    color:brown; 
  }

  button.btn.btn-primary {
    width: 160px;
    height: 55px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-family: 'Poppins';
    font-weight: 500;
    border-radius: 0;
    margin-left: auto;
    margin-top: 20px;
    transition: all 0.5s ease-in-out;
    border:0px solid #0b0b0b !important;
}



/* swiper slider */

.swiper {
  width: 100%;
  height: 100%;
}



.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding-left: 50px;
}


.thanks  .modal-dialog {
  max-width: 40% !important;
  margin: 0 auto;
  top: 50%;
  transform: translatey(-50%) !important;
}

img.parallax-image {
  width: 100%;
  margin-top: -1.66667rem;
  margin-bottom: -1.66667rem;
  top: 90px;
}

.ab-modal .modal-dialog {
  max-width: 80% !important;
  margin: 0 auto;

}

.c-nav_product-link:hover+.c-nav_product-link .c-nav_product-link_wrap {
  transform: translateX(2.66667rem) !important;
}
.c-nav_product-link:hover+.c-nav_product-link .c-nav_product-link_background .o-background {
  transform: translateX(-2.66667rem) !important;
}

/*  */
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
.col-lg-5.epoxy-col {
  position: relative;
}

.concert-content.added-new {
  position: absolute;
  bottom: 4%;
  left: 40px;
}
.concert-content.added-new  h1{
  text-transform: uppercase;
}
.side-space.con-creek .banner-content h1 {
  width: 110%;
}

.side-space.home-pg .border-div2 {
  right: 78rem;
}

.side-space.con-creek .inner-title h1:before {
  right: -250px;
}
.loader img {
  position: absolute;
  top: -220px;
  left: 30%;
}  
.circle.upper-circle a {
  text-decoration: none !important;
  color: white !important;
}
.destop-none{
  display: none !important;
}
.step-concret h2 {
  color: #3B3B3B;
text-transform: uppercase;
font-weight: 600;
}
/* ------------ resp */

@media only screen and (max-width: 1800px) {
  .banner-content h1 {
      font-size: 65px;
  }
  .concert-content.up {
    top: 0px;
}

  .banner-content {

    top: 50%;

    transform: translateY(-50%);

}
  .ul-li-concrete {
    width: 57%;
    margin: 0 auto;
}
.ul-li-concrete {

  position: relative;
  left: 100px;
}
  section.sec-3.about-sec1 .concert-img img {
      width: 1170px;
  }
  section.sec-3.about-sec1 .concert-img {
      left: -74%;
   
  }
  h1.home-title {
      font-size: 65px;
  }
  .concert-content p {
      font-size: 21px;

  }

  
  .swiper-slide img {
      height: 510px;
  }section.sec-3.about-sec1 .concert-content p {
      font-size: 16px;
      width: 100%;
  }
  
  .swiper-slide h2 {
      font-size: 60px;
      bottom: 0;
  }
  .header-contat-info {
      left: 8%;
  }
  .banner-content p {
      font-size: 16px;

  }
  .banner-content h1 {
      font-size: 58px;
  }
  .banner-content:before {

      top: 7px;

  }
  h1.home-title {
      font-size: 55px;
  }
  .concert-content p {
      font-size: 19px;
  }
  footer {
      padding-bottom: 180px;
      padding-top: 50px;
  }
  
  .footer-subcribe h4 {
      font-size: 33px;
  }
  .footer-subcribe ul li a {
      font-size: 20px;
      margin-bottom: 4px;
  }
  
  footer .header-contat-info {
      bottom: 60px;
  }
  .copyright {
      padding: 10px 142px;
  }
  .col-lg-5.epoxy-col img {
      width: 663px;
  }
  .grind img {
      width: 100%;
  }
  
  .Grand-content p {
      font-size: 20px;
  }
  
  .Grand-content h2 {
      font-size: 30px;
  }
  
  .single-para p {
      font-size: 18px;
  }
  
  .inner-title p {
      font-size: 17px;
  }
  
  .inner-title h1 {
      font-size: 50px;
      
  }
  
  span.path-steps {
      left: 66%;
  }
}




@media only screen and (max-width: 1600px) {
  .ul-li-concrete {
    width: 51%;
    left: 50px;
    margin: 0 auto;
}
.ul-li-concrete ul li {
  font-size: 15px;
}
section.sec-3.about-sec1 .concert-img img {
  width: 1100px;
}
  .Grand-content img {
    width: 100px;
    height: 100px;
}
.side-space.home-pg section.sec-4 .concert-img img {
  width: 87%;
}
.testimonial img {
  height: 330px;
}
.side-space.home-pg section.sec-3.hm-com .concert-img img {
  height: 100%;
  max-height: 420px;

}
.border-div3 {
  top: -140px;
}

.side-space.home-pg .border-div2 {
  right: 63rem  !important;
}
.side-space.con-creek  span.path-steps {
  left: 70% !important;
  /* top: 12%; */
}
.social-icons img {
  width: 14px;
  height: 14px;
}

.social-icons {
  bottom: 3rem;
  gap: 13px;
}

.test-content p {
  font-size: 16px;
}
p.extra-para {
font-size: 25px;
}
.Grand-content h2 {
  font-size: 25px;
  text-transform: uppercase;
}
.Grand-content p {
  font-size: 17px;
}
  .header-contat-info a {
      font-size: 19px;
      gap: 10px;
  }
  .inner-title h1 {
      font-size: 40px;
  }
  
  .header-contat-info {
    left: 26%;
    gap: 10rem;
}
  span.path-steps img {
      width: 60%;
  }
  .step-concret h2 {
      font-size: 24px;
      text-transform: uppercase;
  }
.center {

  top: 40%;

}
.single-para p {
  font-size: 16px;
}
span.path-steps {
  left: 68%;
  top: 12%;
}
.step-concret img {
  width: 162px;
}
.center .toggle {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.center .box ul li {
  width: 80px;
  height: 80px;
}

.center .box ul li a {
  font-size: 11px;
}

.center .box.active {
  width: 162px;
  height: 200px;
}
.swiper-slide img {
  height: 460px;
}

.swiper-slide {


  width: 23.7rem !important;

}


.swiper-slide h2 {
  font-size: 44px;
  bottom: 0;
}

.logo-head img {
  width: 150px;
}

div#menu {
  left: 66px;
}
.burger {
  width: 50px;
  height: 48px;
  padding: 13px 11px;
}

div#menu p {
  font-size: 18px;
}

.social-icons {
  left: 74px;
  bottom: 3rem;
}
.header-ab {
  width: 230px;
}
.header-ab nav {
 
  width: 193px;

}
nav.expanded {
  width: 100% !important;
}
.side-space {
  margin-left: 195px;
}
.tabs-header {
  left: 347px;

}
.banner-content h1 {
  font-size: 44px;
}

.banner-content p {
  font-size: 15px;
}

.banner-content a {
  font-size: 15px;
}

h1.home-title {
  font-size: 40px;
}

.concert-content p {
  font-size: 15px;
}

.solid-btn a {
  font-size: 15px;
  width: 140px;
  height: 40px;
}

.col-lg-5.epoxy-col img {
  width: 563px;
}
.side-padding {
  padding-left: 60px;
}

footer {
  padding-bottom: 120px;
  padding-top: 40px;
  padding-left: 250px;
}

footer .header-contat-info {
  left: 370px;
  bottom: 60px;
}

.footer-subcribe h4 {
  font-size: 26px;
}

.footer-subcribe ul li a {
  font-size: 18px;
}

.footer-subcribe form input {
  width: 280px;
  height: 45px;
}
.footer-subcribe form a {
  width: 120px;
  height: 45px;
  font-size: 15px;
}
.header-contat-info a img {
  width: 20px;
  height: 20px;
}
.c-nav_product-link_name {
  font-size: 50px;
  margin-top: -30px;
}
.c-nav_product-link_name {
  font-size: 50px !important;
  margin-top: -30px !important;
}

.drp-iner ul li a {
  font-size: 24px;
}

.drp-iner ul li {
  line-height: 27px;
}
.copyright ul li a {

  padding: 0 13px;

}

}

@media only screen and (max-width: 1440px) {
  .inner-title h1 {
      font-size: 35px;
  }
  .loader img {
    width: 50%;
    top: -180px !important;
    left: 18% !important;
  }
  .copyright {
    padding: 7px 160px;
}
.copyright p {
  font-size: 10px;
}

.copyright ul li a {
  font-size: 10px;
}
  .drp-iner ul li {
    line-height: 27px !important;
}
section.sec-2-concret .Grand-content img {
  width: 26%;
}
.side-space.con-creek section.sec-3-concret .inner-title h1:before {
  right: -200px;
}
  .header-contat-info a {
    font-size: 15px;
    gap: 10px;
}
.loader {
  width: 300px;
}

.loader img {
  left: 20% !important;
}
.header-contat-info {
  left: 26%;
  gap: 7rem;
}
.header-ab nav li {

  line-height: 40px;
  padding-left: 16px;

}
.burger {
  width: 50px;
  height: 46px;
  padding: 13px 11px;
}
.drp-iner ul li a {
  font-size: 20px;
  line-height: 15px !important;
}
.drp-iner {

  top: -10px;

}
.c-nav_product-link_name {
  font-size: 41px !important;
  margin-top: -22px !important;
}
  .inner-title h1:before {
      width: 140px !important;

      height: 2px;

      top: 20px;
      right: -150px;

  }
  .logo-head img {
    width: 130px;
}
  div#menu {
    top: 37% !important;
}
.burger {
  width: 40px;
  height: 40px;
  padding: 11px 6px;
}
  .banner-content p {
  font-size: 13px;
}
  section.sec-2-concret.s-4.timber-sec4 .inner-title h1:before {
      right: -260px;
  }
  .inner-title h1:after {
      width: 140px !important;
      top: 20px;
      left: -190px;
  }
 .center {
  top: 38%;
}
  section.sec-3.about-sec1 .concert-content p {
      font-size: 14px;
      width: 100%;
  }
  section.sec-3.about-sec1 .concert-img {
      left: -75%;
  }
  section.sec-3.about-sec1 .concert-img img {
      width: 940px;
  }
  
  /**/
  
  .border-div {
  width: 160px !important;
  background: red;
  height: 2px;
  position: relative;
  top: -220px;
  right: 16rem;
  margin-left: auto;
  z-index: -1;
  transition: transform 0.2s ease-out;
}
.border-div3 {
  width: 160px !important;
  background: red;
  height: 2px;
  position: relative;
  top: -130px;
  left: -18rem;
  z-index: -1;
  transition: transform 0.2s ease-out;
}   
.border-div2 {
  width: 160px !important;
  background: red;
  height: 2px;
  position: relative;
  top: -210px;
  right: 45rem;
  margin-left: auto;
  z-index: -1;
  transition: transform 0.2s ease-out;
}  
.swiper-slide {
  width: 18.7rem !important;
}
.tabs-header {
  position: absolute;

  top: 6rem;

}
.swiper-container {

  padding-left: 35px;
}
.header-contat-info {
  position: absolute;
  top: -4rem;}


  .col-lg-7.home-con .concert-content {
      left: 16rem;  
  }
  
  section.sec-3.about-sec1.home-sec h1.home-title {
      font-size: 23px;
  }
  .side-space.about-page section.sec-3.about-sec1 .concert-content p {
      font-size: 16px;
      width: 90%;
  }
  section.sec-4 section.sec-3.about-sec1.home-sec .col-lg-7.home-con .concert-content {
      left: 16rem;
      bottom: 1rem;
  }
  .testimonial .slick-next:before, [dir=rtl] .slick-prev:before {

      right: -80px;

  }
  .testimonial .slick-prev:before {

      left: -90px;
  }
  .side-space.home-pg section.sec-4 .concert-img img {
    width: 97%;
}
}


@media only screen and (max-width: 1279px) {
  .logo-head img {
      width: 100px;
  }
  
  .burger {
      width: 35px;
      height: 35px;
      padding: 9px 6px;
  }
  
  .burger span {
      height: 1px;
  }
  
  .social-icons {
    left: 72px;
    bottom: 2rem;
    gap: 0px;
  }
  .social-icons a img {
    width: 10px;
    height: 11px;
}
  .header-ab nav {
      width: 163px;
  }
  section.sec-2-concret .Grand-content img {
    width: 60px;
    height: 70px;
}
  .side-space {
      margin-left: 164px;
  }
  
  .banner-content {
      margin-left: 30px;
  }
  
  div#menu p {
      font-size: 13px;
  }
  
  .headMeanus ul li a {
      font-size: 20px;
  }
  
  .tabs-header {
      left: 277px;
  }
  
  .swiper-container {
      padding-left: 0;
  }
  .loader img {
    left: 31% !important;
    top: -115px !important;
      width: 40% !important;
  }
  .loader {
    width: 250px;
    height: 6px;
}
  .headMeanus {
 
    left: -130px;

}
.circle {
  width: 70px !important;
  height: 70px !important;
  line-height: 70px !important;
  transition: all 0.5s ease-in-out;
}

.circle span {
  font-size: 12px !important;
}

.circle-menu {
  bottom: 58px !important;
}
.swiper-slide {
  width: 16.3rem !important;
}
.header-ab nav .header-contat-info a img {
  width: 20px;
  height: 20px;
}

.header-contat-info {
  gap: 4rem;
}
.c-nav_product-link_name {
  font-size: 32px !important;
  margin-top: -17px !important;
}
.drp-iner ul li a {
  font-size: 16px;
  line-height: 8px !important;
}
.drp-iner ul li {
  line-height: 22px !important;
  padding-left: 4px !important;
}
.banner-content:before {
  width: 10px;
  left: -17px;
}

.concert-content:hover:before {
  width: 10px;
  left: -17px;
}

.concert-content:before {
  width: 10px;
  left: -17px;
}

.banner-content h1 {
  font-size: 37px;
}

.banner-content p {
  font-size: 11px;
}

.banner-content p a {
  font-size: 11px;
}

section.sec-3.about-sec1.home-sec h1.home-title {
  font-size: 18px;
  text-transform: uppercase;
}

section.sec-3.about-sec1 .concert-content p {
  font-size: 12px;
}
.col-lg-5.epoxy-col img {
  width: 513px;
}

h1.home-title {
  font-size: 32px;
}

.concert-content p {
  font-size: 12px;
}
.solid-btn a {
  font-size: 12px;
  width: 100px;
  height: 30px;
}

section.sec-3.about-sec1 .concert-img img {
  width: 810px;
}
.home-pg .col-lg-7.home-con .concert-content {
  left: 14rem;
}
.side-space.home-pg .border-div2 {
  right: 52rem !important;
}
footer {
  padding-bottom: 90px;
  padding-top: 20px;
  padding-left: 220px;
}
.footer-subcribe h4 {
  font-size: 20px;
}
.footer-subcribe form input {
  width: 210px;
  height: 40px;
}
.footer-subcribe form a {
  width: 100px;
  height: 40px;
  font-size: 12px;
}
.footer-subcribe ul li a {
  font-size: 13px;
  padding: 4px 8px;

}
footer .header-contat-info {
  bottom: 39px;
}
.copyright p {
  font-size: 8px;
}
.copyright ul li a {
  font-size: 8px;
}
.copyright {
  padding: 4px 160px;
}
.Grand-content h2 {
  font-size: 22px;

}
.Grand-content p {
  font-size: 14px;
}
.single-para p {
  font-size: 13px;
}
.inner-title p {
  font-size: 14px;
}
.furter-inquiry p {
  font-size: 18px;
}

.furter-inquiry h5 {
  font-size: 20px;
}

.furter-inquiry p a {
  font-size: 16px;
}
.inner-title h1 {
  font-size: 30px;
}
.step-concret h2 {
  font-size: 22px;

}
.side-space.con-creek .inner-title h1:before {
  right: -160px;
}
p.extra-para {
  font-size: 19px;
}
.step-concret p{
  font-size: 14px;
}
.step-concret strong {
  font-size: 30px;
  color: #E80000;
}
.step-concret img {
  width: 142px;
}
.inner-title h1:before {
  width: 120px !important;

}
.inner-title h1:after {
  width: 120px !important;

}
.side-space.con-creek section.sec-3-concret .inner-title h1:before {
  right: -140px;
}
.side-space.con-creek section.sec-3-concret .inner-title h1:after {
  left: -163px;
}
p.oneline-para{
  font-size: 19px;
  
  padding-bottom: 0px;

}
section.sec-2-concret {
  padding-top: 50px;
  /* padding-bottom: 70px; */
}
.grind {

  padding-bottom: 50px;
}
.inner-title span {
  width: 10px;
  left: -20px;
}

p.oneline-para.pbapna {
  padding-bottom: 60px;
}

.side-space.timber-pg .inner-title h1:after {
  width: 90px !important;
  left: -122px;
}
.side-space.timber-pg .inner-title h1:before {
  width: 100px !important;
  right: -114px;
}

.side-space.timber-pg section.sec-2-concret.s-4.timber-sec4 .inner-title h1:before {
  right: -200px;
}
.side-space.about-page section.sec-3.about-sec1 .concert-content p {
  font-size: 12px;
  width: 90%;
}
.side-space.about-page section.sec-3.about-sec1 .border-div {
  right: 320px !important;

  bottom: 160px !important;

}
section.sec-4 section.sec-3.about-sec1.home-sec .inner-title h1 {
  font-size: 35px !important;
}
.test-content p {
  font-size: 12px;
width: 80%;
}
.testimonial img {
  height: 260px;
}
.testimonial .slick-next:before, .slick-prev:before {
  font-size: 20px !important;

}
form.contact-form input {
  height: 47px;
  font-size: 11px;
  border-radius: 7px;
}

form.contact-form textarea {
  height: 97px;
  border-radius: 7px;
}

.contact-page h1.home-title:before {
  bottom: 70px;
  left: 210px;
  width: 100px !important;
}
button.btn.btn-primary {
  font-size: 12px;
  width: 100px;
  height: 40px;
}
}
 
@media only screen and (max-width: 991px) {
  section.sec-3-concret.tablet .inner-title h1:before {
    right: -220px !important;
}
div#root {
  overflow-x: hidden;
}
.side-space.con-creek section.sec-2-concret.tablet .inner-title h1:before {
  right: -130px;
}
section.sec-3-concret.tablet .inner-title h1:after {
    left: -111px !important;
}
  .banner-content h1 {
    font-size: 40px;
}
.banner-content p {
  font-size: 15px;
}
.logo-head img {
  width: 81px;
}
.header-ab nav {
  width: 133px;
  z-index: 9999999999999999999999999999999999999999;   
}
div#menu {
  left: 50px;
}
.burger {
  border: 1px solid black;
}
.side-space {
  margin-left: 131px;
}
.table-none{
  display: none !important;
}
.bg-video-wrap {
  height: 100vh;
}
.social-icons {
  bottom: 7rem;
}
.circle-menu {
  bottom: 438px!important;
}
.destop-none{
  display: block !important;
}
.side-padding {
  padding-left: 30px;
}
/* .inner-title h1:before {
  display: none;
}

.inner-title h1:after {
  display: none;
} */

.concert-tablet img {
  width: 100%;
  margin-bottom: 15px;
}
.concert-content {
  left: 20px;
}
.headMeanus ul li a {
  font-size: 15px;
}
.tabs-header {
  left: 237px;
}
.swiper-slide {
  width: 12.3rem !important;
}
.tabs-header {
  left: 143px;
}
body.over-remove.nav nav.expanded .logo-head {opacity: 0;}

body.over-remove.nav nav.expanded .social-icons {
    opacity: 0;
}
.headMeanus {
  display: none;
}
body.over-remove.nav nav.expanded  .headMeanus {
  display: block;
}

.header-ab nav .header-contat-info a img {
  width: 15px;
  height: 15px;
}
body.over-remove.nav nav.expanded div#menu {
  top: 24% !important;
}
.drp-iner {
  top: 0px;
}
.header-contat-info a {
   font-size: 10px;
   gap: 3px;
}
.c-nav_product-link_name {
  font-size: 24px !important;
  margin-top: -12px !important;
}
.header-contat-info a:before {
   width: 50px;
   left: -61px;
}

.header-contat-info {
   gap: 0rem;
}
.side-space.home-pg section.sec-3 {
  padding-bottom: 40px;
}
.side-space.home-pg section.sec-3.about-sec1.home-sec {
  padding-top: 30px;
}

.copyright {
  padding: 4px 30px;
}
footer {

  padding-left: 157px;
}
h1.home-title {
  font-size: 30px;
}
.footer-subcribe form input {
  width: 160px;
  height: 35px;
}
.footer-subcribe form a {
  width: 80px;
  height: 35px;
  font-size: 12px;
}
.footer-subcribe h4 {
  font-size: 19px;
}
footer .header-contat-info {
  left: 230px;

}
.banner-content p, .banner-content p a {
  font-size: 15px;
}
div#menu {
  top: 21%!important;
}
section.sec-3.about-sec1 .concert-img {
  position: initial;
}

section.sec-3.about-sec1 .concert-img img {
  width: 100%;
}
section.sec-3.about-sec1.tb-mb .concert-content:before {
  display: none;
}
.side-space.about-page section.sec-3.about-sec1.tb-mb .concert-content p {
  text-align: center;
  width: 100% !important;
}
.side-space.about-page section.sec-3.about-sec1.tb-mb{
  padding-top: 30px;
  padding-bottom: 30px;
}
section.sec-4.tablet-sec-4  .row {
  display: flex;
  flex-direction: column-reverse;

}
section.sec-4.tablet-sec-4{
  padding-bottom: 0;
  padding-top: -50px;
}
.side-space.about-page section.sec-4 {
  padding-bottom: 30px;
}
.side-space.about-page section.sec-3 {
  padding-bottom: 30px;
}
.test-content span {
  line-height: 17px;
  font-size: 29px;
  color: black;
  position: relative;
  top: 18px;
}
.testimonial img {
  height: 170px;
}
.test-content p {
  width: 100%;
}

.test-content h6 {
  width: 100%;
  font-size: 15px;
}

.test-content p {
  font-size: 10px;
}
.parent-testimonial {
  padding-bottom: 40px;
}
section.sec-1-contact {
  width: 100%;
  padding: 50px 0;
}

.contact-page h1.home-title:before {
  display: none;
}
p.oneline-para {
  font-size: 15px;
}
.side-space.timber-pg .inner-title h1:before {
  width: 60px !important;
  right: -116px;
}
.side-space.timber-pg .inner-title h1::after {
  width: 60px !important;

}
.side-space.timber-pg section.sec-2-concret.s-4.timber-sec4 .inner-title h1:before {
  right: -80px;
}
.inner-title {

  width: 80%;

}
.side-space.timber-pg .inner-title p {

  width: 100%;

}
.side-space.timber-pg section.sec-2-concret.s-4.timber-sec4 {
  padding: 0;
}
.inner-title h1:before {
  width: 80px !important;
  right: -90px;
}
.inner-title h1::after {
  width: 80px !important;
  left: -105px;
}
section.sec-2-concret .Grand-content {
  width: 95%;

}
.inner-title p {

  width: 97%;

}
.row.sec3row3 span.path-steps {
  top: 6%;
}
.inner-title p br {

display: none;

}
.thanks .modal-dialog {
  max-width: 60% !important;

}
.thanks .modal-dialog  h1.home-title {
  font-size: 40px;
}
.banner-content h1 {
  font-size: 40px;
  width: 110%;
}
.side-padding.over-pg  .inner-title {
  width: 100%;
}
p.extra-para br {
  display: none;
}
.side-space.con-creek .inner-title h1:before {
  right: -90px;
}
.side-space.con-creek .inner-title.vc h1::before {
  right: -170px;
}
  
    .banner-content {

      top: 50%;

      transform: translateY(-50%);

  }
  .ul-li-concrete ul li {
    font-size: 11px;
}

.ul-li-concrete {
    width: 100%;
}

.ul-li-concrete ul li {
    width: 36%;
}

.ul-li-concrete ul li:before {
    top: 2px;
}
.fade.ab-modal.modal.show {
  z-index: 999999999999999999;
}
}

@media only screen and (max-width: 767px) {
  nav.collapsed {
    width: 100% !important;
    height: 80px !important;
}
div#menu {
  top: 50px !important;
  left: initial;
  right: 20px;
  z-index: 99999999999999999;
}
.header-contat-info {
  display: none;
}
body.over-remove.nav nav.expanded .logo-head {
  opacity: 1;
}
body.over-remove.nav nav.expanded .social-icons {
  opacity: 1;
}
.social-icons{
  display: none;
}
body.over-remove.nav nav.expanded .social-icons {
 display: flex;
}
.logo-head {
  text-align: center;
  position: absolute;
  left: 26px;
  top: 6px;
}
.logo-head img {
  width: 70px;
}

.swiper-slide {
  width: 100% !important;
}
.c-nav_product-link:last-child {
  margin-left: 0;
  padding-left: 0;
  margin-right: 0;
}
.side-space {
  margin-left: 0;
}
.banner-content h1 {
  font-size: 25px;
}

.banner-content p, .banner-content p a {
  font-size: 12px;
}

.inner-title h1:before {
display: none;
}
.inner-title h1:after {
  display: none;
  }
  .c-nav_product-link_wrap {

    height: 57vh;

}
body.over-remove.nav nav.expanded div#menu {
  top: 20px !important;
  transform: initial !important;
  z-index: 99999999999999999999999999999999999999999;
  right: initial;
  left: 350px;
}
footer {
  padding-left: 15px;
}
.footer-subcribe form input {
  width: 140px;
  height: 30px;
  font-size: 13px;
  padding: 0 9px;
}

.footer-subcribe form a {
  width: 53px;
  height: 30px;
  font-size: 9px;
}
footer .header-contat-info a {
  width: 150px;
}

footer .header-contat-info a:before {
  width: 20px;
  left: -25px;
}
footer .header-contat-info {
  display: flex;
  left: 50px;
}
.copyright p {
  display: none;
}

.copyright {
  justify-content: center;
  flex-direction: column;
}
p.whodesign {
  display: block;
}
.onlymobadd p {
  font-size: 8px;
  text-align: center;
  color: red;
  margin: 6px 0px;
}
.onlymobadd  {
display: block;
}
span.path-steps {
  display: none;
}
section.sec-2-concret .Grand-content img {
  width: 110px;
  height: 110px;
}
span.path-steps {
  display: none;
}

.ul-li-concrete ul {
  flex-direction: column;
}

.inner-title {
  width: 100%;
}
.contact-page h1.home-title {
  position: relative;
  text-align: center;
}
.thanks .modal-dialog {
  max-width: 90% !important;
}
.modal.ab-modal .inner-title {
  padding: 0 0px;
}

.modal.ab-modal .modal-body {
  padding: 0px 7px;
  z-index: 9999999999;
}
button.btn-close {
  border: 1px solid red;
  border-radius: 50px;
  color: red;
  filter: brightness(0.5) invert(4) grayscale(-13);
}
.circle-menu {
  bottom: 238px!important;
}
.onlymobileshow{
  display: block;
}
.showdesktab{
  display: none;
}
.ab-modal .modal-dialog {
  max-width: 90% !important;
  margin: 0 auto;
}
.c-nav_product-link:hover .c-nav_product-link_background .o-background {
  transform: translateX(0);
}
.c-nav_product-link_background {
  right: 0;
}

.c-nav_product-link:last-child {
  margin-right: 0.33333rem;
  padding: 0 !important;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding-left: 0px !important;
}
.c-nav_product-link:hover .c-nav_product-link_name {
  transform: translateX(0) !important;
}
.tabs-header {
  left: 113px;
}
.test-content p {
  font-size: 8px;
}

.test-content h6 {
  font-size: 13px;
  margin-bottom: 0;
}

.testimonial img {
  height: 146px;
}

section.sec-3.about-sec1.tb-mb .concert-content.destop-none.pb-3 {
  left: 0;

}
}


@media only screen and (max-width: 424px) {
  h1.home-title {
    font-size: 26px;
}
.footer-subcribe h4 {
  font-size: 16px;
}
.footer-subcribe form a {
  width: 49px;
  height: 30px;
  font-size: 8px;
}
.footer-subcribe form input {
  width: 130px;
  height: 30px;
  font-size: 11px;
  padding: 0 8px;
}
.footer-subcribe {
  position: relative;
  left: -30px;
}

footer .col-lg-6.col-md-6.col-6 {
  width: 100%;
}
.banner-content {
  margin-left: 0px;
}
.c-nav_product-link:hover .c-nav_product-link_background .o-background {
  transform: translateX(0);
}
.c-nav_product-link_background {
  right: 0;
}

.c-nav_product-link:last-child {
  margin-right: 0.33333rem;
  padding: 0 !important;
}

.swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
  padding-left: 0px !important;
}
.c-nav_product-link:hover .c-nav_product-link_name {
  transform: translateX(0) !important;
}
.tabs-header {
  left: 113px;
}
.inner-title h1 {
  font-size: 19px;
}
footer {
  text-align: center;
  padding-left: 0;
}
.footer-subcribe {

  left: 0px;

}
.footer-subcribe form {
  justify-content: center;
}

.footer-subcribe ul li {
  justify-content: center;
}

footer .header-contat-info {
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0;
  top: 0;
  width: 100%;
}

footer .header-contat-info a {
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

footer {
  padding-bottom: 40px;
}

.circle {
  width: 50px !important;
  height: 50px !important;
  line-height: 50px !important;
}

.circle-menu {
  right: -14px;
}
body.over-remove.nav nav.expanded div#menu {

  left: 270px;
}
.copyright ul li a {
  padding: 6px;
}
.c-nav_product-link_wrap {

  height: 57vh;

}
.test-content {
  gap: 10px;
}

.test-content h6 {
  font-size: 10px;
}
.testimonial .ad-test {
    width: 100%;
}
span.client-left {
  display: none;
}

span.client-right {
  display: none;
}
}