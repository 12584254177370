html {
    scroll-behavior: smooth;
  }

  svg.svg-inline--fa.fa-plus {
    color: rgb(255, 255, 255);
    font-size: 45px;
    margin: -8px;
}
svg.svg-inline--fa.fa-minus {
    font-size: 45px;
    margin: -8px;
}
.circle-menu {
    position: fixed;
    bottom: 99px;
    right: 0px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  
  .circle {
    right: 10%;
    bottom: -80px;
    transform: translate(-50%,50%);
    width: 120px;
    height: 120px;
    background: #ff0000;
    color: aliceblue;
    text-align: center;
    line-height: 120px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
  .circle span {
    font-size: 23px;
    font-family: 'Chandler-Mountain';
    font-weight: 500;
  }
  
  .circle.open {
    background: #ff0000;
  
}
  
  .upper-circle {
    background: #ff0000;  
    color: aliceblue;
    margin-bottom: 20px;
    }
