.c-nav_products-list {
    width: 100%;
    height: calc(100vh - 4.66667rem);
    white-space: nowrap;
}

.c-nav_product-link:hover .c-nav_product-link_wrap {
    transform: translateX(-2.66667rem);
}

.c-nav_product-link {
    position: relative;
    width: 26.66667rem;
    height: 100%;
    white-space: normal;
    color: #010101;
    transform: translateX(-5.33333rem);
    opacity: 1;
    transition: transform .6s cubic-bezier(0.365, 0.305, 0, 1), opacity .3s cubic-bezier(0.365, 0.305, 0, 1);
}

.c-nav_product-link:last-child {
    overflow: hidden;
    width: calc(26.66667rem + 2.66667rem);
    margin-left: -2.66667rem;
    padding-left: 2.66667rem;
    margin-right: 5.33333rem;
}

.o-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
}

.c-nav_product-link_background .o-background {
    left: -2.66667rem;
    right: 2.66667rem;
    transition: transform .45s cubic-bezier(0.365, 0.305, 0, 1);
}

.c-nav_product-link_name {
    position: relative;
    display: inline-block;
    margin-top: -3.33333rem;
    margin-left: 1.06667rem;
    transform: translateZ(0);
    font-size: 78px;
    line-height: 1.06667;
    transition: transform .45s cubic-bezier(0.365, 0.305, 0, 1) 0.05s;
    font-family: 'Chandler-Mountain';
    font-weight: bold;
    color: #3B3B3B;
}

.c-nav_product-link:hover .c-nav_product-link_background .o-background {
    transform: translateX(2.66667rem);
}

.c-nav_product-link_background {
    position: absolute;
    top: 0;
    right: -10.66667rem;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.c-nav_product-link:hover {
    color: #010101;
}

.c-nav_product-link_wrap {
    display: block;
    position: relative;
    height: 67vh;
    transform: translateZ(0);
    transition: transform .45s cubic-bezier(0.365, 0.305, 0, 1);
}

.o-layout {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    margin-left: 0;
    display: flex;
}

.c-nav_product-link:hover .c-nav_product-link_name {
    transform: translateX(-2.66667rem);
}

.has-nav-open .c-nav_product-link.-visible {
    transform: translateX(0);
    opacity: 1;
    transition-delay: 0.1s;
}

.has-nav-open .c-nav_product-link:nth-child(6).-visible {
    transition-delay: .8s;
}

.c-nav_product-link:hover .c-nav_product-link_wrap {
    transform: translateX(-2.66667rem);
}

.c-nav_product-link:hover+.c-nav_product-link .c-nav_product-link_wrap {
    transform: translateX(2.66667rem);
}