@media only screen and (max-width: 1800px) {
    .banner-content h1 {
        font-size: 65px;
    }

    section.sec-3.about-sec1 .concert-img img {
        width: 1170px;
    }

    section.sec-3.about-sec1 .concert-img {
        left: -74%;

    }

    h1.home-title {
        font-size: 65px;
    }
    div#menu {
        top: 36% !important;
    }
    .concert-content p {
        font-size: 21px;

    }


    .swiper-slide img {
        height: 510px;
    }

    section.sec-3.about-sec1 .concert-content p {
        font-size: 16px;
        width: 100%;
    }

    .swiper-slide h2 {
        font-size: 60px;
        bottom: 0;
    }

    .header-contat-info {
        left: 8%;
    }

    .banner-content p {
        font-size: 16px;

    }

    .banner-content h1 {
        font-size: 58px;
    }

    .banner-content:before {

        top: 7px;

    }

    h1.home-title {
        font-size: 55px;
    }

    .concert-content p {
        font-size: 19px;
    }

    footer {
        padding-bottom: 180px;
        padding-top: 50px;
    }

    .footer-subcribe h4 {
        font-size: 33px;
    }

    .footer-subcribe ul li a {
        font-size: 20px;
        margin-bottom: 4px;
    }

    footer .header-contat-info {
        bottom: 60px;
    }

    .copyright {
        padding: 13px 160px;
    }

    .col-lg-5.epoxy-col img {
        width: 663px;
    }

    .grind img {
        width: 100%;
    }

    .Grand-content p {
        font-size: 20px;
    }

    .Grand-content h2 {
        font-size: 30px;
    }

    .single-para p {
        font-size: 18px;
    }

    .inner-title p {
        font-size: 17px;
    }

    .inner-title h1 {
        font-size: 50px;
    }

    span.path-steps {
        left: 66%;
    }

.circle {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.circle span {
    font-size: 18px;
}

.circle span img {
    width: 70%;
}
.circle-menu {

    bottom: 80px !important;
}
.loader-counter {
    font-size: 4rem !important;
}
}




@media only screen and (max-width: 1600px) {
    .test-content p {
        font-size: 16px;
    }

    .header-contat-info a {
        font-size: 19px;
        gap: 10px;
    }

    .inner-title h1 {
        font-size: 40px;
    }

    .header-contat-info {
        left: 8%;
        gap: 14rem;
    }

    span.path-steps img {
        width: 60%;
    }

    .step-concret h2 {
        font-size: 24px;
    }

    .center {

        top: 40%;

    }

    .single-para p {
        font-size: 16px;
    }

    span.path-steps {
        left: 68%;
        top: 12%;
    }

    .step-concret img {
        width: 162px;
    }

    .center .toggle {
        width: 100px;
        height: 100px;
        line-height: 100px;
    }

    .center .box ul li {
        width: 80px;
        height: 80px;
    }

    .center .box ul li a {
        font-size: 11px;
    }

    .center .box.active {
        width: 162px;
        height: 200px;
    }

    .swiper-slide img {
        height: 460px;
    }

    .swiper-slide {


        width: 23.7rem !important;

    }


    .swiper-slide h2 {
        font-size: 44px;
        bottom: 0;
    }

    .logo-head img {
        width: 130px;
    }

    div#menu {
        left: 66px;
    }

    .burger {
        width: 60px;
        height: 53px;
        padding: 13px 11px;
    }

    div#menu p {
        font-size: 18px;
    }

    .social-icons {
        left: 74px;
        bottom: 2rem;
    }

    .header-ab {
        width: 230px;
    }

    .header-ab nav {

        width: 193px;

    }

    nav.expanded {
        width: 100% !important;
    }

    .side-space {
        margin-left: 195px;
    }

    .tabs-header {
        left: 347px;

    }

    .banner-content h1 {
        font-size: 44px;
    }

    .banner-content p {
        font-size: 15px;
    }

    .banner-content a {
        font-size: 15px;
    }

    h1.home-title {
        font-size: 40px;
    }

    .concert-content p {
        font-size: 15px;
    }

    .solid-btn a {
        font-size: 15px;
        width: 140px;
        height: 40px;
    }

    .col-lg-5.epoxy-col img {
        width: 563px;
    }

    .side-padding {
        padding-left: 60px;
    }

    footer {
        padding-bottom: 120px;
        padding-top: 40px;
        padding-left: 250px;
    }

    footer .header-contat-info {
        left: 370px;
        bottom: 60px;
    }

    .footer-subcribe h4 {
        font-size: 26px;
    }

    .footer-subcribe ul li a {
        font-size: 18px;
    }

    .footer-subcribe form input {
        width: 280px;
        height: 45px;
    }

    .footer-subcribe form a {
        width: 120px;
        height: 45px;
        font-size: 15px;
    }

    .header-contat-info a img {
        width: 20px;
        height: 20px;
    }

    .c-nav_product-link_name {
        font-size: 50px;
        margin-top: -30px;
    }

    .c-nav_product-link_name {
        font-size: 50px !important;
        margin-top: -30px !important;
    }

    .drp-iner ul li a {
        font-size: 24px;
    }

    .drp-iner ul li {
        line-height: 22px;
    }

    section.sec-4 section.sec-3.about-sec1.home-sec .inner-title h1 {
        font-size: 45px !important;
    }

}

@media only screen and (max-width: 1440px) {
    .inner-title h1 {
        font-size: 35px;
    }
    .swiper.swiper-initialized.swiper-horizontal.swiper-backface-hidden.mySwiper {
        padding-left: 40px !important;
    }
    .swiper-container {
        padding-left: 0px;
    }
    .circle {
        width: 80px !important;
        height: 80px !important;
        line-height: 80px !important;
    }
    
    .circle span {
        font-size: 14px !important;
    }
    
    .circle span img {
        width: 60% !important;
    }
    .circle-menu {
    
        bottom: 80px !important;
    }
    .inner-title h1:before {
        width: 140px !important;

        height: 2px;

        top: 20px;
        right: -150px;

    }

    .banner-content p {
        font-size: 13px;
    }

    section.sec-2-concret.s-4.timber-sec4 .inner-title h1:before {
        right: -260px;
    }

    .inner-title h1:after {
        width: 140px !important;
        top: 20px;
        left: -190px;
    }

    .center {
        top: 38%;
    }

    section.sec-3.about-sec1 .concert-content p {
        font-size: 14px;
        width: 100%;
    }

    section.sec-3.about-sec1 .concert-img {
        left: -75%;
    }

    section.sec-3.about-sec1 .concert-img img {
        width: 940px;
    }

    /**/

    .border-div {
        width: 160px !important;
        background: red;
        height: 2px;
        position: relative;
        top: -220px;
        right: 16rem;
        margin-left: auto;
        z-index: -1;
        transition: transform 0.2s ease-out;
    }

    .border-div3 {
        width: 160px !important;
        background: red;
        height: 2px;
        position: relative;
        top: -130px;
        left: -30rem !important;
        z-index: -1;
        transition: transform 0.2s ease-out;
    }

    .border-div2 {
        width: 160px !important;
        background: red;
        height: 2px;
        position: relative;
        top: -210px;
        right: 55rem !important;
        margin-left: auto;
        z-index: -1;
        transition: transform 0.2s ease-out;
    }

    .swiper-slide {
        width: 18.7rem !important;
    }

    .tabs-header {
        position: absolute;

        top: 6rem;

    }



    .header-contat-info {
        position: absolute;
        top: -4rem;
    }


    .col-lg-7.home-con .concert-content {
        left: 16rem;
    }

    section.sec-3.about-sec1.home-sec h1.home-title {
        font-size: 23px;
    }

    .side-space.about-page section.sec-3.about-sec1 .concert-content p {
        font-size: 16px;
        width: 90%;
    }

    section.sec-4 section.sec-3.about-sec1.home-sec .col-lg-7.home-con .concert-content {
        left: 16rem;
        bottom: 1rem;
    }

    .testimonial .slick-next:before,
    [dir=rtl] .slick-prev:before {

        right: -80px;

    }

    .testimonial .slick-prev:before {

        left: -90px;
    }
}


